import React from "react";
import "../asset/bootstrap/css/bootstrap-grid.min.css";
import "../asset/bootstrap/css/bootstrap-reboot.min.css";
import "../asset/bootstrap/css/bootstrap.min.css";
import "../asset/dropdown/css/style.css";
import "../asset/theme/css/style.css";
import "../asset/web/assets/mobirise-icons2/mobirise2.css";
import "../asset/mobirise/css/mbr-additional.css?v=1mBzCf";
import "../asset/mobirise/css/mbr-additional.css?v=1mBzCf";
import "../asset/mobirise/css/mbr-additional.css";
import "../asset/parallax/jarallax.css";
import { Link } from "react-router-dom";

function Printad_Web() {
  return (
    <>
      <section
        data-bs-version="5.1"
        className="menu menu1 cid-ufjolJHAUz"
        once="menu"
        id="menu01-0"
      >
        <nav className="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
          <div className="container">
            <div className="navbar-brand">
              <span className="navbar-caption-wrap">
                <Link
                  className="navbar-caption text-black text-primary display-4"
                  to="/"
                >
                  TNT
                </Link>
              </span>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-bs-toggle="collapse"
              data-target="#navbarSupportedContent"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div className="hamburger">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul
                className="navbar-nav nav-dropdown nav-right"
                data-app-modern-menu="true"
              >
                <li className="nav-item">
                  <Link
                    className="nav-link link text-black text-primary display-4"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link link text-black text-primary display-4"
                    to="/"
                    aria-expanded="false"
                  >
                    Print Ad
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link link text-black text-primary display-4"
                    to="/"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>

      <section
        data-bs-version="5.1"
        className="header4 cid-ufq7zNn4OI"
        id="header04-a"
      >
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md">
              <div className="text-wrapper">
                <h2 className="mbr-section-title mb-4 mbr-fonts-style display-2">
                  TNT Transparency You Can Trust
                </h2>
                <p className="mbr-text mb-4 mbr-fonts-style display-7">
                  &nbsp;
                </p>
              </div>
            </div>
            <div className="mbr-figure col-12 col-md-7">
              <iframe
                className="mbr-embedded-video"
                src="https://www.youtube.com/embed/DcVHENYMyHw?rel=0&amp;amp;&amp;showinfo=0&amp;autoplay=0&amp;loop=1&amp;playlist=DcVHENYMyHw"
                width="1280"
                height="540"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <section
        data-bs-version="5.1"
        className="header16 cid-ufjx18q16r mbr-fullscreen mbr-parallax-background"
        id="header17-6"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="content-wrap col-12 col-md-12">
              <h1 className="mbr-section-title mbr-fonts-style mbr-white mb-4 display-1">
                <br />
                Transparency You Can Trust
              </h1>
              <p className="mbr-fonts-style mbr-text mbr-white mb-4 display-7">
                &nbsp;
              </p>
              <div className="mbr-section-btn">
                <Link className="btn btn-white-outline display-7" to="/learn-more">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        data-bs-version="5.1"
        className="header16 cid-ufjr58iwX3 mbr-parallax-background"
        id="header17-2"
      >
        <div className="container">
          <div className="row">
            <div className="content-wrap col-12 col-md-12">
              <h1 className="mbr-section-title mbr-fonts-style mbr-white mb-4 display-2">
                <br />
                we let our product <br />
                speak for itself.
              </h1>
              <h2 className="mbr-section-subtitle mbr-fonts-style mbr-white mb-4 display-2">
                &nbsp;
              </h2>
              <p className="mbr-fonts-style mbr-text mbr-white mb-4 display-7">
                &nbsp;
              </p>
              <div className="mbr-section-btn">
                <Link className="btn btn-white-outline display-7" to="/learn-more">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        data-bs-version="5.1"
        className="header16 cid-ufjvbmdd2W mbr-parallax-background"
        id="header17-4"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="content-wrap col-12 col-md-12">
              <h1 className="mbr-section-title mbr-fonts-style mbr-white mb-4 display-5">
                <br />
                &nbsp;
              </h1>

              <p className="mbr-fonts-style mbr-text mbr-white mb-4 display-5">
                &nbsp; &nbsp; &nbsp; &nbsp; With TNT transactions are seamless
                and secure
              </p>
              <div className="mbr-section-btn">
                <Link className="btn btn-white-outline display-7" to="/learn-more">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        data-bs-version="5.1"
        className="article11 cid-ufjvKd55Ue"
        id="article11-5"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="title col-md-12 col-lg-10">
              <h4 className="mbr-section-subtitle align-center mbr-fonts-style mt-4 display-7">
                <strong>TNT: Transparency You Can Trust</strong>
                <div>
                  <br />
                </div>
                <div>
                  At TNT, we prioritize your interests above all else. We
                  understand that all expenses, including advertising, are
                  ultimately paid for by our clients. That's why we're committed
                  to being frugal with your money. Unlike FTX, which
                  <Link
                    to="https://www.vogue.com/article/gisele-bundchen-ftx-cyrpto-philanthropy-campaign-interview"
                    className="text-primary"
                    target="_blank"
                  >
                    spent $20 million on celebrities like Tom Brady and Gisele
                    Bündchen
                  </Link>
                  , we take a more prudent approach. We've hired a good-looking,
                  reasonably priced model to capture your attention, but here's
                  the real difference:
                  <strong>we let our product speak for itself.</strong>
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <strong>
                    Discover Transparent Network Technologies (TNT):
                    True-NO-Trust
                  </strong>
                </div>
                <div>
                  <br />
                </div>
                <div>
                  Our innovative audit file format guarantees the immutability
                  of all embedded transactions, ensuring absolute transparency
                  and security. We don’t dazzle you with gimmicks; we clearly,
                  precisely, and exactly explain why you should support the TNT
                  initiative.
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <strong>
                    Everything Bitcoin and Ethereum Can Do, TNT Can Do Better!
                  </strong>
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <strong>Discover the Superior Power of TNT:</strong>
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <strong>1. Unmatched Security</strong>: Every update is signed
                  by every wallet, making fraud provably impossible.
                </div>
                <div>
                  &nbsp; &nbsp; Transactions must be universally agreed upon by
                  all bank clients to be valid.
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <strong>2. Cost-Effective and Fast</strong>: Our transaction
                  costs are significantly lower, settlements are quicker, and we
                  can
                </div>
                <div>
                  &nbsp; &nbsp; &nbsp;process thousands of transactions per
                  second. TNT is a true medium of exchange, not just an
                  expensive toy.
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <strong>3. Full AML Compliance:</strong> TNT uniquely
                  facilitates full AML compliance by refusing credits from
                  disreputable
                </div>
                <div>
                  &nbsp; &nbsp; &nbsp;wallets or sources, ensuring the integrity
                  of your transactions.
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <strong>4. Legally Binding Smart Contracts:</strong> TNT smart
                  contracts are dually signed by both counterparties, making
                </div>
                <div>
                  &nbsp; &nbsp; &nbsp;them legally binding and adding an extra
                  layer of security and trust.
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <strong>And More...</strong>
                </div>
                <div>
                  <br />
                </div>
                <div>
                  All the features of Ethereum and Bitcoin are reflected in TNT.
                  Whether it's smart contracts, decentralization, or robust
                  blockchain technology, TNT does it all better, faster, and
                  more securely. TNT is also environmentally friendly; our first
                  use case is a carbon credit-backed green coin that requires no
                  electricity to mine. In TNT, there is no need to prove honesty
                  by wasting electricity solving meaningless mathematical
                  puzzles. Fraud facilitated by asymmetric information about
                  pending payments, such as double spending of funds, becomes
                  absolutely impossible when all nodes are symmetrically
                  informed about all pending transactions through Transparent
                  Network Technology (TNT). This technology restricts payment
                  processing to pre-scheduled future crossing sessions via batch
                  processing transactions; for example, collecting debits during
                  odd minutes and processing matching credits during even
                  minutes, just as normal banks collect checks during the day
                  and process them at night while halting the acceptance of new
                  checks.
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <strong>TNT: The Future of Blockchain Payment Systems</strong>
                </div>
                <div>
                  <br />
                </div>
                <div>Experience the difference. Experience TNT.</div>
                <div>
                  <br />
                </div>
                <div>
                  We know, it’s not fashionable to be old school, but that’s
                  what we are – old school. I'm the youngest in our group at 58,
                  with 30 years of experience trading stat arb on Wall Street. I
                  have no desire to risk my reputation or freedom for short-term
                  gains. Speaking of risks, unlike others in the industry, we
                  are exceptionally risk-averse. We already have plenty of
                  money, live well, and are not willing to compromise our
                  principles for more.
                </div>
                <div>
                  <br />
                </div>
                <div>
                  Our commitment extends beyond profits. We're passionate about
                  the environment and despise waste. TNT offers the same
                  benefits as Bitcoin but without the excessive energy
                  consumption. Faster, cheaper, and more secure, TNT also
                  facilitates AML compliance, making it the responsible choice.
                </div>
                <div>
                  <br />
                </div>
                <div>
                  We adhere strictly to the law. TNT will not sell any
                  securities without a no-action letter from the SEC for TNT One
                  True Money as a utility token, and we will register Green Coin
                  with the SEC as an asset-backed security. This commitment
                  ensures full independent auditing and AML compliance by
                  reputable institutions like JP Morgan.
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <strong>
                    Join us in supporting a transparent, efficient, and secure
                    financial future with TNT.
                  </strong>
                </div>
              </h4>
            </div>
          </div>
        </div>
      </section>

      <section
        data-bs-version="5.1"
        className="header16 cid-ufjyAAU461 mbr-fullscreen mbr-parallax-background"
        id="header17-7"
      >
        <div className="container">
          <div className="row">
            <div className="content-wrap col-12 col-md-12">
              <h1 className="mbr-section-title mbr-fonts-style mbr-white mb-4 display-5">
                <strong>
                  <br />
                  &nbsp;
                </strong>
              </h1>

              <p className="mbr-fonts-style mbr-text mbr-white mb-4 display-2">
                &nbsp;Join us and be <br />
                &nbsp;part of the solution
              </p>
              <div className="mbr-section-btn">
                <Link className="btn btn-primary-outline display-7" to="/">
                  TNTCRYPTO.COM
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        data-bs-version="5.1"
        className="features035 cid-ufjzEA3bqJ"
        id="features035-8"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-9 active">
              <div className="item-wrapper">
                <div className="card-box">
                  <h4 className="card-text mbr-fonts-style display-5">
                    Embrace the Future of Financial Freedom
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 active">
              <div className="mbr-section-btn">
                <Link className="btn btn-primary-outline display-7" to="/">
                  Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        data-bs-version="5.1"
        className="header16 cid-ufjBRoqCph mbr-fullscreen mbr-parallax-background"
        id="header17-9"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="content-wrap col-12 col-md-12">
              <h1 className="mbr-section-title mbr-fonts-style mbr-white mb-4 display-1">
                <br />
                <br />
              </h1>

              <p className="mbr-fonts-style mbr-text mbr-white mb-4 display-2">
                Embrace the Future of Financial Freedom
              </p>
              <div className="mbr-section-btn">
                <Link className="btn btn-white-outline display-7" to="/learn-more">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="display-7"
        style={{
          padding: 0,
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          alignContent: "center",
          display: "flex",
          position: "relative",
          height: "4rem",
        }}
      >
        {/* <Link
          to="https://mobiri.se/3146194"
          style={{
            flex: "1 1",
            height: "4rem",
            position: "absolute",
            width: "100%",
            zIndex: 1,
          }}
        >
          <img
            alt=""
            style={{ height: "4rem" }}
            src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
          />
        </Link>
        <p
          style={{ margin: 0, textAlign: "center" }}
          className="display-7"
        >
          &#8204;
        </p> */}
        <Link style={{ zIndex: 1 }} to="/">
          © Copyright 2024
        </Link>
      </section>
    </>
  );
}

export default Printad_Web;
